<template>
  <div>
    <algolia-select
      v-model="queryString"
      :config="algoliaConfig"
      index="tasks"
      type="text"
      placeholder="Search reference, subject, site, participants..."
      class="fxd-algolia-input"
      icon="algolia"
      icon-pack="fab"
      @focusedselected="onFocusedSelected"
      @fallback-to-firebase="onFallback"
      @movedown="scrollIntoView"
      @moveup="scrollIntoView"
    >
      <template
        slot="list"
        slot-scope="{ results, hits, loading, processing, loadMore, focused }"
      >
        <loading v-if="loading" centered />
        <no-results
          v-else-if="!$_.isEmpty(results) && !$_.get(results, '[0].nbHits')"
          icon="algolia"
          icon-pack="fab"
          title="No tasks found"
          class="is-vertically-centered"
        >
          <span slot="message" class="is-size-7"
            >We couldn't find any tasks<br />
            matching the query '{{ queryString }}'
          </span>
        </no-results>

        <div>
          <list-card
            v-for="hit in hits"
            ref="cards"
            :key="hit.objectID"
            :route="routeFunc(hit)"
            :color="$_.get(hit, `colors.${userId}`, '')"
            :title="`${hit.reference} `"
            :label="$store.getters['tasks/task/status'](hit.status)"
            :timestamp="$moment.unix(hit.dateCreated_timestamp).toDate()"
            :avatar="getAvatar(hit)"
            :class="focused && focused.taskId === hit.taskId ? 'focused' : null"
            @click.native="$emit('select', hit)"
          >
            <strong slot="title" class="is-spaced-apart">
              <algolia-highlight field="reference" :hit="hit" />
              <b-icon
                v-if="getPriorityIcon(hit.priority)"
                v-bind="getPriorityIcon(hit.priority)"
              />
            </strong>

            <p
              slot="subtitle-dark"
              class="is-size-7 has-overflow-ellipsis-right"
            >
              <algolia-highlight field="taskType" :hit="hit" />
              <span v-if="hit.subject">:&nbsp;</span>
              <algolia-highlight
                v-if="hit.subject"
                field="subject"
                :hit="hit"
              />
            </p>

            <p
              slot="subtitle-grey"
              class="is-size-7 has-text-grey has-overflow-ellipsis-right"
            >
              <span>
                <algolia-highlight field="fqdn" :hit="hit" />&nbsp;
                <span v-if="getEmail(hit)">
                  (<algolia-highlight field="authorEmail" :hit="hit" />)
                </span>
              </span>
            </p>
          </list-card>

          <load-more
            v-if="!loading && !$_.get(results, '[0].isComplete', true)"
            :loading="processing"
            @loadMore="loadMore"
          />
        </div>
      </template>
    </algolia-select>

    <slot name="nosearch" v-bind="{ isEmpty: $_.isEmpty(queryString) }" />
  </div>
</template>

<script>
import { teams, getPriorityIcon } from "@src/data/task";
import { getScrollParent, isInViewport } from "@src/services/htmlElemUtils.js";

export default {
  name: "AlgoliaTaskList",
  components: {
    "algolia-select": () => import("@shared/algolia/_algoliaSelect.vue"),
    "algolia-highlight": () => import("@shared/algolia/_algoliaHighlight")
  },
  props: {
    routeFunc: {
      type: Function,
      required: true
    },
    taskFilter: {
      type: String,
      default: ""
    },
    teamFilter: {
      type: String,
      default: ""
    },
    userId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      queryString: "",
      params: {
        highlightPreTag: "<span class='match'>",
        highlightPostTag: "</span>",
        hitsPerPage: 20
      }
    };
  },
  computed: {
    algoliaConfig() {
      const query = {
        indexName: "tasks",
        query: this.queryString,
        params: this.params
      };

      switch (this.taskFilter) {
        case "all":
          return [{ ...query, filters: `_tags:is_all` }];
        case "attention-required":
          return [{ ...query, filters: `_tags:is_attention_required` }];
        case "my":
          return [
            { ...query, filters: `_tags:is_active AND agents:${this.userId}` }
          ];
        case "unassigned":
          return [{ ...query, filters: `_tags:is_unassigned` }];
        case "queued":
          return [{ ...query, filters: `_tags:is_queued` }];
        case "scheduled":
          return [{ ...query, filters: `_tags:is_scheduled` }];
        case "waiting-for-reply":
          return [{ ...query, filters: `_tags:is_waiting_for_reply` }];
        case "all-closed":
          return [{ ...query, filters: `_tags:is_all_closed` }];
      }

      switch (this.teamFilter) {
        case teams.MAINTENANCE.key:
          return [
            {
              ...query,
              filters: `_tags:is_team AND team:${teams.MAINTENANCE.key}`
            }
          ];
      }

      return [query];
    },
    scrollContainer() {
      return getScrollParent(this.$el);
    }
  },
  methods: {
    getPriorityIcon,
    scrollIntoView() {
      if (!this.$refs.cards || !this.scrollContainer) return;

      const focused = this.$refs.cards.find(i =>
        this.$_.includes(i.$el.classList, "focused")
      );

      if (focused && !isInViewport(this.scrollContainer, focused.$el, false)) {
        focused.$el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    },
    onFallback() {
      this.queryString = "";
    },
    onFocusedSelected(hit) {
      if (hit && this.$_.get(this.$route, "params.taskId") !== hit.taskId) {
        this.$router.push(this.routeFunc(hit));
      }
    },
    getEmail(hit) {
      return this.$_.has(hit, `participantsData.${hit.authorId}`)
        ? hit.participantsData[hit.authorId].email
        : "";
    },
    getAvatar(hit) {
      if (this.$_.isEmpty(hit.alphaAgent)) return null;
      return {
        name: this.$_.get(hit, `participantsData.${hit.alphaAgent}.name`, ""),
        url: this.$_.get(
          hit,
          `participantsData.${hit.alphaAgent}.avatarUrl`,
          ""
        )
      };
    }
  }
};
</script>

<style lang="scss">
.algolia-highlight {
  .match {
    font-style: normal;
    background-color: #ffff00a8;
    padding: 0.25rem 0 0.25rem 0.25rem;
  }
}
.is-vertically-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
</style>
